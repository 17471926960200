<template>
  <v-container>
    <p class="text-center text-h3 my-10 titulo-card">Help Center</p>
    <p class="text-center">Explore our tutorials for teachers and students access to Lee Lee App</p>
    <v-row justify="center">
        <v-col cols="4" xl="3">
            <v-card class="mx-auto">
                <v-img
                src="../../../assets/admin/video-teacher.png"
                height="250px"
                @click="mostrarVideo('teacher')"
                ></v-img>

                <v-card-title>
                Teacher Access
                </v-card-title>

                <v-card-subtitle>
                    In this tutorial, teachers will learn how to log in and create their classrooms in Lee Lee Teacher Edition. To successfully log in to Lee Lee Teacher Edition.
                </v-card-subtitle>

                <v-card-actions>
                <v-btn
                    color="orange lighten-2"
                    text
                    @click="mostrarVideo('teacher')"
                >
                    View
                </v-btn>

                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="4" xl="3">
            <v-card class="mx-auto">
                <v-img
                src="../../../assets/admin/video-student.png"
                height="250px"
                @click="mostrarVideo('student')"
                ></v-img>

                <v-card-title>
                Student Access
                </v-card-title>

                <v-card-subtitle>
                    In this tutorial teachers will learn how to register their and enable student access in the Lee Lee Student Edition. To successfully log in to Lee Lee Student Edition.
                </v-card-subtitle>

                <v-card-actions>
                <v-btn
                    color="orange lighten-2"
                    text
                    @click="mostrarVideo('student')"
                >
                    View
                </v-btn>

                </v-card-actions>
            </v-card>
        </v-col>     
    </v-row>
    <v-dialog v-model="dialogVideo" max-width="700px">
        <div style="position:relative;">
            <v-btn tile color="blue-grey" dark style="position:absolute; right:0px;" @click="dialogVideo = false">
                <v-icon left>mdi-close</v-icon>Close
            </v-btn>
        </div>
      <iframe
        width="800"
        height="500"
        :src="result"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </v-dialog>
  </v-container>
</template>
<script>
export default {

  data() {
    return {
        dialogVideo:false,
        urlVideo: '',
        result:'',
        //isClever: false
    }
  },
  created () {

  },
  methods: {
    mostrarVideo(tipoVideo){
        if (tipoVideo == 'teacher') {
            this.urlVideo = 'https://youtu.be/0zpthigPG2U';
        }else{
            this.urlVideo = 'https://youtu.be/ZkKqvC18__Y';
        }
        this.dialogVideo = true;
        this.loadURL();
    },
    loadURL () {
      const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
      const url = this.urlVideo.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      console.log("url", url)
      const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
      console.log("YId", YId)
      if (YId === url[0]) {
        console.log("not a youtube link")
      } else {
        console.log("it's  a youtube video")
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId)
      console.log("topOfQueue", topOfQueue)
      this.result = topOfQueue
      this.youtubeURL = ''
    },
    close () {
      this.dialogVideo = false
      this.result = '';
    },
  },
  mounted() {
    // db.collection('TempTokensClever').doc(this.user.userUID).get().then((doc) => {
    //     if(doc.exists){
    //       isClever=true;
    //     }
    // })
  },
  watch:{
    dialogVideo (val) {
        val || this.close()
    },
  }
}
</script>
<style>
    .imagen-info{
        
    }
</style>